import * as React from "react"
import styled from 'styled-components';

// styles
const sectionRoot = {
}

const sectionInnerDiv = {
  padding: '120px 0px 0px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const genesisCollectionText = {
  fontSize: '3em'
}

const genesisDescription1 = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const genesisDescription2 = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const tier3FarmersHuntersForWL = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const galleryRow = {
  marginTop: '100px',
  width: '100%'
}

const imgStyle = {
  height: '400px',
  width: '400px'
}

const topRow = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  padding: '0% 15%'
}

const leftColumn = {
  display: 'flex', 
  flex: 0.5,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: '50px',
  paddingTop: '30px'
}

const rightColumn = {
  display: 'flex',
  flexDirection: 'column',
  flex: 0.5,
  alignItems: 'flex-start',
  justifyContent: 'center',
}

const logoContainer = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}

const projectLogo = {
  width: '100%',
  minWidth: '250px'
}

const largeText = {
  fontSize: '3.5em',
  marginBottom: '40px',
  lineHeight: '5.1rem',
  color: '#fad3a7',
  textAlign: 'center'
}

const largeTextLine1 = {
  fontSize: '3.8vw',
  color: '#fad3a7',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  marginBottom: '40px',
  fontFamily: "Reggae One"
}

const largeTextLine2 = {
  fontSize: '3.5em',
  marginBottom: '40px',
  color: '#fad3a7',
  textAlign: 'center',
  whiteSpace: 'nowrap'
}

const medText = {
  fontSize: '1.6vw',
  lineHeight: '2.3vw',
  fontFamily: 'Lato'
}

const Wrapper = styled.div`
  width: 100%;
  
  li.alice-carousel__stage-item :not(.__cloned) {
    width: auto !important;
    margin-right: 3rem;
  }
`;

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 4 },
  1024: { items: 8 },
};

const items = [
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/farmer_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/hunter_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/scientist_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/cleric_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/invisibilus_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/aristocrat_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/farmer_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/hunter_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/scientist_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/cleric_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/invisibilus_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/aristocrat_apprentice.jpeg" onDragStart={handleDragStart} />,
];


// markup
const Section = (props) => {
  const {mobile} = props;
  
  return (
    <div style={{
      ...sectionRoot,
      padding: mobile ? '0px 0px 0px 0px' : '0px 0px 0px 0px'
    }}>
      <div style={{
        ...sectionInnerDiv,
        padding: mobile ? '100px 0px' : '150px 0px 200px 0px',
        }}>
      <div style={{
          ...topRow,
          padding: mobile ? '0px 5%' : '0% 15%',
          flexDirection: mobile ? 'column-reverse' : 'row'
          }}>
          <div style={{
            ...leftColumn,
            paddingRight: mobile ? '0px' : '50px'
          }}>
          <div style={{
              ...largeTextLine1,
              whiteSpace: mobile ? null : 'nowrap',
              fontSize: mobile ? '2.6rem' : '3.2vw'
            }}>The Raccoon Heartbeat</div>
            <div style={{
              ...medText,
              fontSize: mobile ? '1.4rem' : '1.6vw',
              lineHeight: mobile ? '1.8rem' : '2.3vw',
            }}>We will go live on social media and video platforms to randomly select Battle Events using an on-chain Plutus Smart Contract for full transparency. These Battle Events result in random Raccoons winning extra battle points based on the traits of the Raccoon.</div>
          </div>
          <div style={rightColumn}>
            <div style={logoContainer}>
              <img style={projectLogo} src="https://rswebpublic.s3.amazonaws.com/gfx/heartbeat_tv.png" />
            </div> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section
