import * as React from "react"
import AliceCarousel from 'react-alice-carousel';
import styled from 'styled-components';
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';

let responsive = {
  0: { items: 4 },
};

// styles
const sectionRoot = {
}

const sectionInnderDiv = {
  padding: '100px 0px 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const faqSectionTitle = {
  fontSize: '3.5vw'
}

const teamMembersRow = {
  marginTop: '50px',
  display: 'flex',
  flexDirection: 'row'
}

const CaretClosed = styled.div`
  color: #fff;
  font-size: 1.7em;
  animation: rotate-open 0.5s forwards;

  @keyframes rotate-open {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-180deg);
    }
  }
`;

const CaretOpen = styled.div`
  color: #fff;
  font-size: 1.7em;
  animation: rotate-closed 0.5s forwards;

  @keyframes rotate-closed {
    0% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-0deg);
    }
  }
`;


const faqBoxOuterStyle = {
  marginTop: '30px',
  backgroundColor: '#000',
  width: '65%',
  padding: '30px',
  borderRadius: '20px'
}

const faqRow = {
  width: '100%',
  marginBottom: '10vh',
}

const faqTitleRow = {
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  borderBottom: '1px solid #555'
}

const faqTitleCell = {
  display: 'flex',
  flex: 0.9,
  paddingBottom: '15px'
}

const faqItemTitle = {
  color: '#fff',
  fontSize: '1.8vw',
  fontFamily: 'Lato'
}

const faqToggleButtonCell = {
  display: 'flex',
  flex: 0.1,
  paddingBottom: '15px'
}

const faqToggleButton = {
  color: '#fff',
  fontSize: '1.7em',
}

const faqAnswerRow = {
  marginTop: '20px'
}

const faqAnswerText = {
  color: '#fff',
  fontSize: '1.5vw',
  fontFamily: 'Lato'
}

const logoContainer = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const projectLogo = {
  width: '500px',
  marginBottom: '100px'
}

const galleryRow = {
  marginTop: '50px',
  width: '100%',
  marginBottom: '100px'
}

const socialLink = {
  textDecoration: 'none',
  color: '#6575ff'
}

const Wrapper = styled.div`
  width: 100%;
  
  li.alice-carousel__stage-item :not(.__cloned) {
    width: auto !important;
    margin-right: 3rem;
  }
`;

const handleDragStart = (e) => e.preventDefault();


let imgStyle= {
  height: '400px',
}



// markup
const SectionFAQ = (props) => {
  const {mobile, showLargeImageModal} = props;

  const windowWidth = useWindowWidth();

  const [showWhenMint, setShowWhenMint] = React.useState(false);
  const [showHowMuch, setShowHowMuch] = React.useState(false);
  const [showHowMany, setShowHowMany] = React.useState(false);
  const [showHowGetWL, setShowHowGetWL] = React.useState(false);

  const resetShowStateToFalse = () => {
    setShowWhenMint(false);
    setShowHowMuch(false);
    setShowHowMany(false);
    setShowHowGetWL(false)
  }

  if (mobile) {
    imgStyle = {
      ...imgStyle,
      height: '44vw'      
    }
    responsive = {
      0: { items: 2 },
    };
  } else if (windowWidth > 3300) {
    responsive = {
      0: { items: 7 },
    };
  } else if (windowWidth > 2800) {
    responsive = {
      0: { items: 6 },
    };
  }


  const items = [
    <div><img key={"img1"} data-value="1" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/1k_Farmer2_PitchforkPipe_01.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/4k_Farmer2_PitchforkPipe_01.jpg')} /></div>,
    <div><img key={"img2"} data-value="2" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/1k_Hunter2_BloodyKnife_01.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/4k_Hunter2_BloodyKnife_01.jpg')} /></div>,
    <div><img key={"img2"} data-value="2" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/aristocrata-1000.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/aristocrata.jpg')} /></div>,
    <div><img key={"img2"} data-value="2" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/Revenant-Male-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Revenant-Male-4k.png')} /></div>,
    <div><img key={"img2"} data-value="2" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/scientist_1k.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/scientist.jpg')} /></div>,
    <div><img key={"img2"} data-value="2" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/procyonlotora-1000.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/procyonlotora.jpg')} /></div>,
    <div><img key={"img2"} data-value="2" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/Wood-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Wood-4k.png')} /></div>,
    <div><img key={"img2"} data-value="2" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/gold-raccoon-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/goldraccoon.jpeg')} /></div>,
    <div><img key={"img3"} data-value="3" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/Nature-Female-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Nature-Female-4k.png')} /></div>,
    <div><img key={"img3"} data-value="3" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/molotov_1k.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/molotov.jpg')} /></div>,
    <div><img key={"img2"} data-value="2" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/Revenant-Female-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Revenant-Female-4k.png')} /></div>,
    <div><img key={"img4"} data-value="4" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/stone_1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Stone_4k.jpeg')} /></div>,
    <div><img key={"img4"} data-value="4" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/Clay-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Clay-4k.png')} /></div>,
    <div><img key={"img4"} data-value="4" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/cleric1_1k.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/cleric1.jpg')} /></div>,
    <div><img key={"img4"} data-value="4" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/steel-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/steel-4k.jpeg')} /></div>,
    <div><img key={"img4"} data-value="4" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/1k_HunterCOM_MilitaryBatDynamite_01.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/4k_HunterCOM_MilitaryBatDynamite_01.jpg')} /></div>,
    <div><img key={"img7"} data-value="7" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/Nature-Male-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Nature-Male-4k.png')} /></div>,
    <div><img key={"img5"} data-value="5" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/Hunter_COM_Female_GoldfishAxe_01_1000.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Hunter_COM_Female_GoldfishAxe_01.jpg')} /></div>,
    <div><img key={"img6"} data-value="6" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/Hunter_COM_CoinsRumBottle1_01_1000.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/Hunter_COM_CoinsRumBottle1_01.jpg')} /></div>,
    <div><img key={"img6"} data-value="6" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/copper-1k.png" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/copper-4k.jpeg')} /></div>,
    <div><img key={"img6"} data-value="6" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/1k_Hunter1_Knife_01.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/4k_Hunter1_Knife_01.jpg')} /></div>,
    <div><img key={"img7"} data-value="7" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/1k_Farmer2_PitchforkPipe_01.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/4k_Farmer2_PitchforkPipe_01.jpg')} /></div>,
    <div><img key={"img9"} data-value="9" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/1k_FarmerCOM_MilitaryRollingPinMilk_01.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/4k_FarmerCOM_MilitaryRollingPinMilk_01.jpg')} /></div>,
    <div><img key={"img11"} data-value="11" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/1k_Farmer1_WheatCigar_01.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/4k_Farmer1_WheatCigar_01.jpg')} /></div>,
    <div><img key={"img12"} data-value="12" style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/1k/1k_Hunter1_Knife_01.jpg" onDragStart={handleDragStart} onClick={() => mobile ? null : showLargeImageModal('https://rswebpublic.s3.amazonaws.com/raccoons/4k/4k_Hunter1_Knife_01.jpg')} /></div>,    
  ];
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  
  return (
    <div style={sectionRoot}>
      <div style={galleryRow}>
        <Wrapper>
          <AliceCarousel disableButtonsControls infinite autoPlay animationDuration={1200} autoPlayInterval={800} responsive={responsive} mouseTracking items={items} paddingLeft={20} paddingRight={20} />
        </Wrapper>
      </div>
    </div>
  )
}

export default SectionFAQ
