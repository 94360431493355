import * as React from "react"
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';

// styles
const root = {
}

/*
const sectionInnerDiv = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  width: '350px',
  minWidth: '350px',
  maxWidth: '350px',
  minHeight: '340px',
  borderRadius: '15px',
  cursor: 'pointer',
  backgroundSize: 'cover'
}
*/

const sectionInnerDiv = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  borderRadius: '15px',
  cursor: 'pointer',
  backgroundSize: 'cover',
  width: '25vw'
}

const shadeOverlay = {
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  borderRadius: '15px',
  display: 'flex',
  flex: 1,
  alignItems: 'flex-end',
  justifyContent: 'center',
}


/*
const shadeOverlay = {
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '15px',
  minWidth: '350px',
  maxWidth: '350px',
  minHeight: '340px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
}
*/

const innerDiv = {
  padding: '0px 0px 2vw 0px',
}

const cardTitleText = {
  fontSize: '1.3vw',
  fontFamily: `"Croissant One"`,
  color: '#FFF'
}

const modalTitle = {
}

const modalContent = {
}


const InfoCard = (props) => {
  const {cardTitle, backgroundColor, backgroundImage, showModalWithContents, selected, modalContents, children} = props;

  console.log('props are', props);

  const onlyWidth = useWindowWidth();
  
  let cardWidth = onlyWidth / 4;
  let cardHeight = cardWidth;

  let thisModalContents = modalContents;

  console.log('infocard a modalContents', thisModalContents);

  const clickedCard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    console.log('Info Cardzzzzz contents', children);
    showModalWithContents(children)
  }

  return (
    <div style={{
        ...root
      }}>
          <div style={{
            ...sectionInnerDiv,
              backgroundColor: backgroundColor ? backgroundColor : 'rgba(255, 255, 255, 0.1)',
              backgroundImage: `url("${backgroundImage}")`,
              width: `${cardWidth}px`,
              height: `${cardHeight}px`
          }}
          onClick={clickedCard}
          >
            <div 
              style={{
                ...shadeOverlay,
                backgroundColor: selected ? 'rgba(66, 50, 18, 0.7)' : 'rgba(0, 0, 0, 0.55)',
              }}
            >
              <div style={innerDiv}>
                <div style={cardTitleText}>{cardTitle}</div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default InfoCard
