import * as React from "react"
import styled from 'styled-components';

// styles
const sectionRoot = {
}

const sectionInnerDiv = {
  padding: '100px 0px 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const faqSectionTitle = {
  fontFamily: "Reggae One",
  color: '#fad3a7',
}

const teamMembersRow = {
  marginTop: '50px',
  display: 'flex',
  flexDirection: 'row'
}

const CaretClosed = styled.div`
  color: #fff;
  font-size: 1.6em;
  animation: rotate-open 0.5s forwards;

  @keyframes rotate-open {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-180deg);
    }
  }
`;

const CaretOpen = styled.div`
  color: #fff;
  font-size: 1.6em;
  animation: rotate-closed 0.5s forwards;

  @keyframes rotate-closed {
    0% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-0deg);
    }
  }
`;


const faqBoxOuterStyle = {
  marginTop: '30px',
  backgroundColor: '#000',
  padding: '30px',
  borderRadius: '20px'
}

const faqRow = {
  width: '100%',
  marginBottom: '10vh',
}

const faqTitleRow = {
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  borderBottom: '1px solid #555'
}

const faqTitleCell = {
  display: 'flex',
  flex: 0.9,
  paddingBottom: '15px'
}

const faqItemTitle = {
  color: '#fff',
  fontFamily: 'Lato'
}

const faqToggleButtonCell = {
  display: 'flex',
  flex: 0.1,
  paddingBottom: '15px'
}

const faqToggleButton = {
  color: '#fff',
  fontSize: '1.7em',
}

const faqAnswerRow = {
  marginTop: '20px'
}

const faqAnswerText = {
  color: '#fff',
  fontFamily: 'Lato'
}

const logoContainer = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const projectLogo = {
  width: '500px',
  marginBottom: '100px'
}

const galleryRow = {
  marginTop: '50px',
  width: '100%',
  marginBottom: '100px'
}

const imgStyle= {
  height: '400px',
  width: '400px'
}

const socialLink = {
  textDecoration: 'none',
  color: '#6575ff'
}

const Wrapper = styled.div`
  width: 100%;
  
  li.alice-carousel__stage-item :not(.__cloned) {
    width: auto !important;
    margin-right: 3rem;
  }
`;


// markup
const SectionFAQ = (props) => {
  const {mobile} = props;

  const [showWhatIsRS, setShowWhatIsRS] = React.useState(false);
  const [showUtility, setShowUtility] = React.useState(false);
  const [showWhenMint, setShowWhenMint] = React.useState(false);
  const [showHowMuch, setShowHowMuch] = React.useState(false);
  const [showHowMany, setShowHowMany] = React.useState(false);
  const [showHowGetWL, setShowHowGetWL] = React.useState(false);

  const resetShowStateToFalse = () => {
    setShowWhenMint(false);
    setShowHowMuch(false);
    setShowHowMany(false);
    setShowHowGetWL(false)
  }

  return (
    <div style={sectionRoot}>
      <div style={sectionInnerDiv} id="faq">
          <div style={{
            ...faqSectionTitle,
            fontSize: mobile ? '2.6rem' : '3.5vw'
          }}>FAQ</div>
          <div style={{
            ...faqBoxOuterStyle,
            width: mobile ? '80%' : '65%',
          }}>
            <div style={faqRow}>
              <div style={faqTitleRow} onClick={() => {
                let originalValue = showWhatIsRS;
                resetShowStateToFalse();
                if (!originalValue) {
                  setShowWhatIsRS(true);
                } else {
                  setShowWhatIsRS(false);
                }
              }}>
                <div style={faqTitleCell}>
                  <div style={{
                    ...faqItemTitle,
                    fontSize: mobile ? '1.4rem' : '1.6vw',
                  }}>
                    1. What is Raccoon Syndicate?
                  </div>
                </div>
                <div style={faqToggleButtonCell}>
                  {!showWhatIsRS && 
                    <CaretOpen>
                      ^
                    </CaretOpen>
                  }
                  {showWhatIsRS && 
                    <CaretClosed>
                      ^
                    </CaretClosed>
                  }
                </div>
              </div>
              {showWhatIsRS &&
                <div style={faqAnswerRow}>
                  <div style={{
                    ...faqAnswerText,
                    fontSize: mobile ? '1.1rem' : '1.3vw',
                  }}>
                    Raccoon Syndicate is a high-quality, next-generation, 3D rendered Cardano NFT project aiming to explore Plutus powered smart contract gaming. Holding a Raccoon automatically enables you to start earning Battle Points in our Leaderboard Game. No need to download any app or game onto your machine - the goal of Raccoon Syndicate is to push the envelope of passive gaming based on what is in your wallet.
                  </div>
                </div>
              }
            </div>
            <div style={faqRow}>
              <div style={faqTitleRow} onClick={() => {
                let originalValue = showUtility;
                resetShowStateToFalse();
                if (!originalValue) {
                  setShowUtility(true);
                } else {
                  setShowUtility(false);
                }
              }}>
                <div style={faqTitleCell}>
                  <div style={{
                    ...faqItemTitle,
                    fontSize: mobile ? '1.4rem' : '1.6vw',
                  }}>
                    2. Does Raccoon Syndicate have utility?
                  </div>
                </div>
                <div style={faqToggleButtonCell}>
                  {!showUtility && 
                    <CaretOpen>
                      ^
                    </CaretOpen>
                  }
                  {showUtility && 
                    <CaretClosed>
                      ^
                    </CaretClosed>
                  }
                </div>
              </div>
              {showUtility &&
                <div style={faqAnswerRow}>
                  <div style={{
                    ...faqAnswerText,
                    fontSize: mobile ? '1.1rem' : '1.3vw',
                  }}>
                    Each Raccoon held in a wallet will reward that owner with a new NFT at the end of each 2 month Battle Round. The quality/rarity of the NFT will be determined by how many Battle Points were earned by that Raccoon over the last 2 month Battle Round. These reward NFTs can be Raccoon Syndicate Upgrade NFTs which will increase your Battle Points modifier for the next Battle Round. Alternatively, the Upgrade NFTs can be sold on popular NFT marketplaces. On occassion, the reward NFTs may also be NFTs from popular Cardano NFT collections that partner with Raccoon Syndicate.
                  </div>
                </div>
              }
            </div>
            <div style={{
              ...faqRow,
              marginBottom: '30px'
            }}>
              <div style={faqTitleRow} onClick={() => {
                let originalValue = showHowGetWL;
                resetShowStateToFalse();
                if (!originalValue) {
                  setShowHowGetWL(true);
                } else {
                  setShowHowGetWL(false);
                }
              }}>
                <div style={faqTitleCell}>
                  <div style={{
                    ...faqItemTitle,
                    fontSize: mobile ? '1.4rem' : '1.6vw',
                  }}>
                    3. How can I play the game?
                  </div>
                </div>
                <div style={faqToggleButtonCell}>
                  {!showHowGetWL && 
                    <CaretOpen>
                      ^
                    </CaretOpen>
                  }
                  {showHowGetWL && 
                    <CaretClosed>
                      ^
                    </CaretClosed>
                  }
                </div>
              </div>
              {showHowGetWL &&
                <div style={faqAnswerRow}>
                  <div style={{
                    ...faqAnswerText,
                    fontSize: mobile ? '1.1rem' : '1.3vw',
                  }}>
                    Simply by a Raccoon from any of the Cardano NFT marketplaces, then head over to <a href="https://game1.raccoonsyndicate.com" style={{color: '#fff'}}>https://game1.raccoonsyndicate.com</a> to visit our game site and the game FAQ which can be found there.
                  </div>
                </div>
              }
            </div>
          </div>
      </div>
      <div style={logoContainer}>
        <img style={{
          ...projectLogo,
          width: mobile ? '90%' : '500px'
        }} src="https://rswebpublic.s3.amazonaws.com/gfx/rs_logo_3d.png" />
      </div> 
    </div>
  )
}

export default SectionFAQ
