import * as React from "react"
import {useRef} from "react"
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled'
import { Tween, ScrollTrigger } from 'react-gsap';
import useIntersection from '../../hooks/useIntersection'

// styles
const sectionRoot = {
  visibility: 'visible'
}

const sectionInnderDiv = {
  padding: '5vw 0px 10vw 0px'
}

const horizontalTopLevelWrapper = {
  display: 'flex',
}

const leftColumn = {
  flex: 0.48,
  display: 'flex',
  flexDirection: 'column',
}

const leftLeftColumn = {
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
}

const leftRightColumn = {
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start'
}

const centerColumn = {
  flex: 0.002,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0px 1vw',
  marginTop: '180px',
  width: '740px'
}

const rightColumn = {
  flex: 0.48,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '0.5vw'
}

const rightLeftColumn = {
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end'
}

const rightRightColumn = {
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 50px 0px 50px',
  alignItems: 'flex-end', 
}


const supplyBoxWrapper = {
  margin: '40px 0px 50px 0px'
}

const imageWrapper = {
}

const syndicateNameHeader = {
  fontFamily: `"Eagle Lake"`
}

const syndicateDescriptionWrapper = {
  fontFamily: 'Lato',
}

const supplyText = {
  fontFamily: `"Delius Swash Caps"`,
  marginTop: '15px'
}

const dateContainer = {
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const dateText = {
  fontFamily: 'Lato',
  marginTop: '10px',
  textAlign: 'center'
}

const raccoonBottomSeparator = {
  width: '300px'
}

// markup
const Section = (props) => {
  const {
    syndicateImageLeft, 
    syndicateNameLeft, 
    syndicateDescriptionLeft, 
    supplyLeft,
    syndicateImageRight, 
    syndicateNameRight, 
    syndicateDescriptionRight,     
    supplyRight,
    backgroundImageUrl,
    presaleDate,
    publicDate,
    mintPeriod,
    versusImage,
    firstRow,
    id,
    mobile,
    backgroundColor, 
    supplyBoxBackgroundColor, 
    numSupply
  } = props;

  let interRef = useRef(null);

  const tweenLeft = useRef(null);
  const tweenRight = useRef(null);


  let app = useRef(null);

  let leftRaccoonImage = useRef(null);
  let rightRaccoonImage = useRef(null);
/*
  useEffect(() => {
    console.log(app);
    gsap.to(app, {css: {visibility: 'visible'}, duration: 0})
  });
  */
 
  const onlyWidth = useWindowWidth();

  const inViewport = useIntersection(interRef, '-200px'); // Trigger if 200px is visible from the element

  if (inViewport) {
    if (firstRow) {
      if (window && window.location && window.location.href && window.location.href.indexOf('#syndicates') > -1) {
        console.log('doing play with window.location', window.location);
        tweenLeft.current.getGSAP().play(0);
        tweenRight.current.getGSAP().play(0);
      }
    }
  }
  

  const showBackground = false;

  return (
    <div id={id} style={{
        background: showBackground ? `url("${backgroundImageUrl}")` : null,
        marginBottom: mobile ? '150px' : null,
        ...sectionRoot
      }}
        ref={el => {
          app = el;
        }}
      >
      <div style={sectionInnderDiv} ref={interRef}>
        <div style={{
          ...horizontalTopLevelWrapper,
          flexDirection: mobile ? 'column' : 'row'
        }}>
          <div style={leftColumn}>
            <div style={{
              ...leftLeftColumn,
              alignItems: 'center',
              padding: mobile ? '0px' : '0px 3vw 0px 3vw',
              textAlign: mobile ? 'center' : null
            }}>
              {!mobile && 
                <ScrollTrigger start="-600px center" end="50px center" scrub={0.5}>
                <Tween
                ref={tweenLeft}
                from={{
                    x: '-40vw'
                }}
                  to={{
                    x: '0px',
                  }}
                  duration={0.5}
                >
                  <img 
                    src={syndicateImageLeft} 
                    style={{
                      width: mobile ? '95%' : `${onlyWidth / 3.5}px`,
                    }}
                  />
                </Tween>
              </ScrollTrigger>    
              }
              {mobile &&
                <img 
                src={syndicateImageLeft} 
                style={{
                  width: mobile ? '100%' : `${onlyWidth / 3.5}px`,
                }}
              />
              }
              <span style={{
                ...syndicateNameHeader,
                marginBottom: mobile ? '10px' : null,
                fontSize: mobile ? '2.4rem' : '2.5vw',
                textAlign: mobile ? null : 'right',
                width: mobile ? null : '100%'
              }}>{syndicateNameLeft}</span>
              <div style={syndicateDescriptionWrapper} style={{
                ...syndicateDescriptionWrapper, 
                textAlign: mobile ? 'center' : 'right',
                fontSize: mobile ? '1.2rem' : '1.35vw'
              }}>
                {syndicateDescriptionLeft}
              </div>
              <div style={{
                ...supplyText,
                fontSize: mobile ? '1.5rem' : '1.6vw',
                textAlign: mobile ? null : 'right',
                width: mobile ? null : '100%'
                }}>
                Supply: {supplyLeft}
              </div>
            </div>
          </div>
          <div style={{
            ...centerColumn,
            width: mobile ? null : '740px',
            marginTop: mobile ? '50px' : '180px',
            marginBottom: mobile ? '10px' : '0px'
          }}>
            <img src={versusImage} 
                  style={{
                    minWidth: mobile ? '80%' : '32vw',
                    maxWidth: mobile ? '80%' : '32vw',
                  }}
            />
            <div style={{
              ...dateContainer,
              marginTop: mobile ? '10px' : '40px',
              marginBottom: mobile ? '50px' : null
            }}>
              {presaleDate &&
                <div style={{
                  ...dateText,
                  fontSize: mobile ? '1.8rem' : '1.6vw'
                }}>
                  Presale: {presaleDate}
                </div>
              }
              {publicDate &&
                <div style={{
                  ...dateText,
                  fontSize: mobile ? '1.8rem' : '1.6vw'
                }}>
                  Public Mint: {publicDate}
                </div>
              }
               {mintPeriod &&
                <div style={{
                  ...dateText,
                  fontSize: mobile ? '1.8rem' : '1.6vw'
                }}>
                  Minted {mintPeriod}
                </div>
              }
            </div>
          </div>
          <div style={rightColumn}>
            <div style={{
              ...rightLeftColumn,
              justifyContent: mobile ? 'center' : 'flex-end',
              alignItems: mobile ? 'center' : null
            }}>
              <div style={{
                ...imageWrapper,
                textAlign: mobile ? 'center' : null
              }}>
                {!mobile && 
                  <ScrollTrigger start="-600px center" end="50px center" scrub={0.5}>
                  <Tween
                  ref={tweenRight}
                  from={{
                      x: '40vw'
                  }}
                    to={{
                      x: '0',
                    }}
                    duration={0.5}
                  >
                    <img 
                      src={syndicateImageRight} 
                      style={{
                        width: mobile ? '90%' : `${onlyWidth / 3.5}px`,
                        marginBottom: "-14px"
                      }}
                    />
                  </Tween>
                </ScrollTrigger> 
                }
                 {mobile &&
                   <img 
                    src={syndicateImageRight} 
                    style={{
                      width: mobile ? '100%' : `${onlyWidth / 3.5}px`,
                      marginBottom: "-14px"
                    }}
                  />
                }
              </div>
              <span style={{
                ...syndicateNameHeader,
                marginTop: mobile ? '10px' : null,
                marginBottom: mobile ? '10px' : null,
                fontSize: mobile ? '2.4rem' : '2.5vw'
              }}>{syndicateNameRight}</span>
              <div style={{
                ...syndicateDescriptionWrapper
                }} style={{
                  ...syndicateDescriptionWrapper, 
                  padding: mobile ? '0px 10%' : null,
                  textAlign: mobile ? 'center' : 'left',
                  fontSize: mobile ? '1.2rem' : '1.35vw'
                }}>
                {syndicateDescriptionRight}
              </div>
              <div style={{
                ...supplyText,
                fontSize: mobile ? '1.5rem' : '1.6vw',
                textAlign: mobile ? null : 'left'
              }}>
                Supply: {supplyRight}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section
