import * as React from "react"
import styled from 'styled-components';
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';

// styles
const sectionRoot = {
}

const sectionInnerDiv = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const genesisCollectionText = {
  fontSize: '3vw',
  fontFamily: "Reggae One",
  textAlign: 'center',
  color: '#fad3a7',
}

const genesisDescription1 = {
  marginTop: '50px',
  padding: '0px 50px',
  fontFamily: 'Lato',
  textAlign: 'center'
}

const genesisDescription2 = {
  fontSize: '1.5vw',
  marginTop: '50px',
  padding: '0px 50px',
  fontFamily: 'Lato',
  textAlign: 'center'
}

const tier3FarmersHuntersForWL = {
  fontSize: '1.5vw',
  marginTop: '50px',
  padding: '0px 50px',
  fontFamily: 'Lato',
  textAlign: 'center'
}

const galleryRow = {
  marginTop: '100px',
  width: '100%'
}

const imgStyle= {
  height: '400px',
  width: '400px'
}

const Wrapper = styled.div`
  width: 100%;
  
  li.alice-carousel__stage-item :not(.__cloned) {
    width: auto !important;
    margin-right: 3rem;
  }
`;

const galleryContainer = {
  maxWidth: '1400px',
  marginTop: '50px',
  marginBottom: '200px'
}


const HexDivRed = styled.div`
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s) * 1.1547);
  display: inline-block;
  font-size:initial;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  margin-bottom: calc(var(--m) - var(--s) * 0.2885);
  background-color: #000;
  background-size: cover;
  background-position: top;
  transform: scale(1);
  z-index: 20;
  position: relative;
  animation: zoom-out 0.3s forwards;

  &:hover {
    z-index: 30;
    animation: zoom-in 0.3s forwards;
  }

  @keyframes zoom-in {
    0% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1.5, 1.5);
    }
  }

  @keyframes zoom-out {
    0% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

const HexOuterContainer = styled.div`
  display:flex;
  --m: 4px;    /* margin */
  --f: calc(var(--s) * 1.732 + 4 * var(--m) - 1px); 
`;

/*
const hexOuterContainer = {
  display: 'flex',
  '--s': '320px',
  '--m': '4px',
  '--f': 'calc(var(--s) * 1.732 + 4 * var(--m) - 1px)' 
}
*/

const HexContainer = styled.div`
  font-size: 0;
  
  &:before {
    content: "";
    width: calc(var(--s) / 2 + var(--m));
    float: left;
    height: 120%; 
    shape-outside: repeating-linear-gradient(#0000 0 calc(var(--f) - 3px), #000 0 var(--f));
  }
`;



/*
const hexDivRed = {
  width: 'var(--s)',
  margin: 'var(--m)',
  height: 'calc(var(--s) * 1.1547)',
  display: 'inline-block',
  fontSize: 'initial',
  clipPath: 'polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%)',
  marginBottom: 'calc(var(--m) - var(--s) * 0.2886)',
  backgroundColor: '#000',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
}
*/

const hexDivGreen = {
  width: 'var(--s)',
  margin: 'var(--m)',
  height: 'calc(var(--s) * 1.1547)',
  display: 'inline-block',
  fontSize: 'initial',
  clipPath: 'polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%)',
  marginBottom: 'calc(var(--m) - var(--s) * 0.2886)',
  backgroundColor: '#000',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
}

const hexImgStyle = {
  height: '300px',
  width: '300px'
}

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 4 },
  1024: { items: 8 },
};

const items = [
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/farmer_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/hunter_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/scientist_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/cleric_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/invisibilus_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/aristocrat_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/farmer_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/hunter_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/scientist_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/cleric_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/invisibilus_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/aristocrat_apprentice.jpeg" onDragStart={handleDragStart} />,
];


// markup
const Section = (props) => {
  const {mobile, showLargeImageModal} = props;

  const windowWidth = useWindowWidth();
  
  let hexWidth = 218;

  if (windowWidth > 1310) {
    hexWidth = 318;
  } 
  else if (windowWidth > 1290) {
    hexWidth = 300;
  }
  else if (windowWidth > 1200) {
    hexWidth = 280;
  } else if (windowWidth > 1150) {
    hexWidth = 260;
  }  else if (windowWidth > 1100) {
    hexWidth = 250;
  }   else if (windowWidth > 1000) {
    hexWidth = 240;
  } 



  return (
    <div style={sectionRoot}>
      <div style={{
        ...sectionInnerDiv,
        padding: mobile ? '20px 0px' : '50px 0px 00px 0px',
      }}>
          <div style={{
            ...genesisCollectionText,
            fontSize: mobile ? '2.6rem' : '3vw',
            marginTop: mobile ? '50px' : '150px'
          }}>Genesis Collection</div>
          <div style={{
            ...genesisDescription1,
            fontSize: mobile ? '1.4rem' : '1.5vw',
            padding: mobile ? '0px 10px' : '0px 50px'
          }}>Raccoons are the genesis collection for our long-term project and web-based leaderboard game.</div>
          <div style={{
            ...genesisDescription2,
            fontSize: mobile ? '1.4rem' : '1.5vw',
            padding: mobile ? '0px 10px' : '0px 50px'
          }}>Owning Raccoons will grant you pre-sale access to follow-on releases that will fuel all Raccoon Syndicate games.</div>
          {!mobile && (
            <>
              <br id="gallery" />
              <br />
              <div style={galleryContainer}>
                <HexOuterContainer className="myHexOuterContainer" style={{
                  "--s": `${hexWidth}px`
                }}>
                  <HexContainer>
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/scientist_1k.jpg")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/scientist.jpg", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/scientist.jpg")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/Revenant-Male-1k.png")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/Revenant-Male-4k.png", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/Revenant-Male-4k.png")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/procyonlotora-1000.jpg")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/procyonlotora.jpg", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/procyonlotora.jpg")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/Nature-Male-1k.png")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/Nature-Male-4k.png", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/Nature-Male-4k.png")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/Clay-1k.png")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/Clay-4k.png", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/Clay-4k.png")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/gfx/rslogo-hex.png")',
                      }}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/molotov_1k.jpg")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/molotov.jpg", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/molotov.jpg")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/Revenant-Female-1k.png")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/Revenant-Female-4k.png", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/Revenant-Female-4k.png")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/aristocrata-1000.jpg")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/aristocrata.jpg", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/aristocrata.jpg")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/cleric1_1k.jpg")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/cleric1.jpg", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/cleric1.jpg")}
                    />
                    <HexDivRed style={{
                      backgroundImage: 'url("https://rswebpublic.s3.amazonaws.com/raccoons/1k/Nature-Female-1k.png")',
                      cursor: 'pointer'
                      }}
                      onClick={() => showLargeImageModal("https://rswebpublic.s3.amazonaws.com/raccoons/4k/Nature-Female-4k.png", "https://rswebpublic.s3.amazonaws.com/raccoons/4k/Nature-Female-4k.png")}
                    />                                
                  </HexContainer>
              </HexOuterContainer>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Section
