import React from 'react';
import App from 'components/App';
import { createRoot } from 'react-dom/client';
import 'react-alice-carousel/lib/alice-carousel.css';
import "@fontsource/croissant-one";
import "@fontsource/lato";
import "@fontsource/eagle-lake";
import "@fontsource/delius-swash-caps";
// choose one below
import "@fontsource/redressed";
import "@fontsource/reggae-one";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
