import * as React from "react"
import {
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size/throttled';
import {
  GlassMagnifier,
} from "react-image-magnifiers";
import Magnifier from "react-magnifier";

// styles
const modalWrapper = {
  display: 'none',
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0, 0, 0)',
  backgroundColor: 'rgba(0, 0, 0, 0.85)',
  overflowY: 'scroll'
}

const modalDiv = {
  backgroundColor: '#222',
  margin: 'auto',
  border: '1px solid #888',
}

// markup
const LargeImageModal = (props) => {
  const {shouldShowModal, setShouldShowModal, largeImageModalUrl, largeImageHiResModalUrl} = props;

  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  let zoomFactor = 0.6;

  if (windowWidth > 2000) {
    zoomFactor = 0.35;
  }
  if (windowWidth > 3000) {
    zoomFactor = 0.25;
  }
  if (windowWidth > 3800) {
    zoomFactor = 0.15;
  }
  
  return (
    <div style={{
      ...modalWrapper,
      display: shouldShowModal === true ? 'block' : 'none'
    }}
    onClick={() => {
      setShouldShowModal(false);
    }}
    >
      <div style={{
        ...modalDiv,
        width: `${windowHeight * 0.95}px`,
        height: `${windowHeight * 0.95}px`,
        marginTop: `${windowHeight * 0.02}px`
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      >
        <div style={{
          display: 'flex',
          height: `${windowHeight * 0.95}px`
        }}>
           <Magnifier src={largeImageModalUrl} zoomImgSrc={largeImageHiResModalUrl} zoomFactor={zoomFactor} mgWidth={windowHeight * 0.35} mgHeight={windowHeight * 0.35} />
        </div>
      </div>
    </div>
  )
}

export default LargeImageModal
