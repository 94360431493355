import * as React from "react"
import TeamMember from "../../components/TeamMember";

// styles
const sectionRoot = {
}

const sectionInnerDiv = {
  padding: '100px 0px 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const genesisCollectionText = {
  fontFamily: "Reggae One",
  color: '#fad3a7',
}

const teamMembersRow = {
  marginTop: '50px',
  display: 'flex',
  flexDirection: 'row'
}


// markup
const Section = (props) => {
  const {mobile} = props;

  return (
    <div id="team" style={sectionRoot}>
      <div style={sectionInnerDiv}>
          <div style={{
            ...genesisCollectionText,
            fontSize: mobile ? '2.6rem' : '3vw'
          }}>Team</div>
          <div style={{
            ...teamMembersRow,
            flexDirection: mobile ? 'column' : 'row',
            width: mobile ? '100%' : null
          }}>
            <TeamMember textAlignment="left" name="Kiama" title="Founding Artist" description="With a background in Design and Animation, Kiama has been working with studios to create characters, environments and film assets for over 10 years. You'll find Kiama at her desktop creating all things visual for Raccoon Syndicate - wardrobe changes, prop creation, lights, camera and action! She also enjoys too much coffee and hopes to one day look good in a hat." image="https://rswebpublic.s3.amazonaws.com/gfx/team_kiama_border.png" mobile={mobile} />
            <TeamMember textAlignment="right" name="Timmy" title="Founding Developer" description="Leveraging 20+ years as a software engineer and manager (including 5 years at a AAA game studio), you can find Timmy working on the Raccoon Syndicate Plutus Contract, Discord integrations, website development and getting ready to launch the RS Leaderboard game! Timmy loves hanging out in Twitter Spaces so be sure to follow and look for him there!" image="https://rswebpublic.s3.amazonaws.com/gfx/team_timmy_border.png" mobile={mobile} />
          </div>
      </div>
    </div>
  )
}

export default Section
