import * as React from "react"
import styled from 'styled-components';

// styles
const root = {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#000',
    zIndex: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const menuContainer = {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: '7px 20px'
}

const leftColumn = {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
}

const rightColumn = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}

const menuItem = {
    margin: '0px 30px'
}

const link = {
    textDecoration: 'none',
    color: '#e08705',
    cursor: 'pointer'
}
  
const twitterLogo = {
    height: '28px',
    cursor: 'pointer',
}

const discordLogo = {
    height: '28px',
    cursor: 'pointer',
}

const tinyLogo = {
    height: '50px'
}

const LinkComponent = styled.a`
  text-decoration: none;
  color: #e08705;
  cursor: pointer;

  &:hover {
    color: #fabc61;
  }
`;

const Navbar = (props) => {
  return (
    <div style={{
        ...root
      }}>
        <div style={menuContainer}>
            <div style={leftColumn}>
                <a style={link} href="#top"><img src="https://rswebpublic2.s3.amazonaws.com/webgfx/rslogo2w512.png" style={{height: '50px'}} /></a>
            </div>
            <div style={rightColumn}>
                <div style={menuItem}>
                    <LinkComponent href="#features">FEATURES</LinkComponent>
                </div>
                <div style={menuItem}>
                    <LinkComponent href="#gallery">GALLERY</LinkComponent>
                </div>
                <div style={menuItem}>
                    <LinkComponent href="#syndicates">SYNDICATES</LinkComponent>
                </div>
                <div style={menuItem}>
                    <LinkComponent href="#team">TEAM</LinkComponent>
                </div>
                <div style={menuItem}>
                    <LinkComponent href="https://game1.raccoonsyndicate.com">GAME</LinkComponent>
                </div>                
                <div style={menuItem}>
                    <LinkComponent href="https://rswebpublic2.s3.amazonaws.com/wppdf/RaccoonSyndicate_Whitepaper_v1.1.2.pdf">WHITEPAPER</LinkComponent>
                </div>
                
                <div style={{
                    marginLeft: '20px'
                }}><a href="https://twitter.com/raccoonsyndicat" target="_new"><img style={twitterLogo} src="https://rswebpublic2.s3.amazonaws.com/webgfx/header-social-twitter.png" /></a></div>
                <div style={{
                    marginLeft: '40px'
                }}><a href="https://discord.gg/j5ZW5uDBx3" target="_new"><img style={discordLogo} src="https://rswebpublic2.s3.amazonaws.com/webgfx/header_social_discord.png" /></a></div>
            </div>
        </div>
    </div>
  )
}

export default Navbar
