import * as React from "react"
import styled from 'styled-components';

// styles
const sectionRoot = {
}

const SectionInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GenesisCollectionText = styled.div`
  font-size: 3em;
`;

const genesisDescription1 = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const genesisDescription2 = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const tier3FarmersHuntersForWL = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const galleryRow = {
  marginTop: '100px',
  width: '100%'
}

const imgStyle = {
  height: '400px',
  width: '400px'
}

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0% 15%;
`;

const LeftColumn = styled.div`
  display: flex; 
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  max-width: 800px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  align-items: center;
  justify-content: center;
`;

const logoContainer = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}

const projectLogo = {
  width: '100%',
  minWidth: '350px'
}

const largeText = {
  fontSize: '3.5em',
  marginBottom: '40px',
  lineHeight: '5.1rem',
  color: '#fad3a7',
  textAlign: 'center'
}

const largeTextLine1 = {
  color: '#fad3a7',
  textAlign: 'center',
  marginBottom: '40px',
  fontFamily: "Reggae One"
}

const largeTextLine2 = {
  fontSize: '3.5em',
  marginBottom: '40px',
  color: '#fad3a7',
  textAlign: 'center',
  whiteSpace: 'nowrap'
}


const medText = {
  fontFamily: 'Lato'
}

const Wrapper = styled.div`
  width: 100%;
  
  li.alice-carousel__stage-item :not(.__cloned) {
    width: auto !important;
    margin-right: 3rem;
  }
`;

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 4 },
  1024: { items: 8 },
};

const items = [
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/farmer_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/hunter_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/scientist_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/cleric_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/invisibilus_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/aristocrat_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/farmer_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/hunter_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/scientist_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/cleric_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/invisibilus_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/aristocrat_apprentice.jpeg" onDragStart={handleDragStart} />,
];


// markup
const Section = (props) => {
  const {mobile} = props;
  
  return (
    <div id="features" style={{
      ...sectionRoot,
      padding: mobile ? '30px 0px' : '150px 0px 120px 0px'
    }}>
      <SectionInnerDiv id="test1">
        <TopRow id="test2" style={{
          padding: mobile ? '0px 15%' : '0% 15%',
          flexDirection: mobile ? 'column-reverse' : 'row'
          }}>
          <LeftColumn id="test3" >
            <div id="test4" style={{
              ...largeTextLine1,
              fontSize: mobile ? '2.6rem' : '3.2vw',
              whiteSpace: mobile ? null : 'nowrap',
            }}>Six Syndicates at War!</div>
            <div id="test5" style={{
              ...medText,
              fontSize: mobile ? '1.4rem' : '1.6vw',
              lineHeight: mobile ? '1.8rem' : '2.3vw',
              textAlign: 'center'
            }}>Farmers vs Hunters - Scientists vs Clerics - Aristocrats vs the Procyon Lotor - are all at war! Collectively, these 6 Syndicates form the full set of 10,000 Raccoons!</div>
          </LeftColumn>
        </TopRow>
      </SectionInnerDiv>
    </div>
  )
}

export default Section
