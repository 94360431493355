import React from 'react';
// import 'assets/scss/App.scss';
import reactLogo from 'assets/img/react_logo.svg';

class WelcomePage extends React.PureComponent {
  render() {
    return (
      <div>
        hi2
      </div>
    );
  }
}

export default WelcomePage;
