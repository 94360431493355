import * as React from "react"

// styles
const sectionRoot = {
  backgroundColor: '#000'
}

const sectionInnderDiv = {
  padding: '20px 0px 20px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const genesisCollectionText = {
  fontSize: '3em'
}

const teamMembersRow = {
  marginTop: '50px',
  display: 'flex',
  flexDirection: 'row'
}

const logosRow = {
  display: 'flex',
  flexDirection: 'row'
}

const logoContainer = {
  margin: '0px 50px 0px 20px'
}

const twitterLogo = {
  height: '28px',
  cursor: 'pointer',
}

const discordLogo = {
  height: '28px',
  cursor: 'pointer',
}

// markup
const SectionFooter = () => {
  return (
    <div style={sectionRoot}>
      <div style={sectionInnderDiv}>
        <div style={logosRow}>
          <div style={logoContainer}><a href="https://twitter.com/raccoonsyndicat" target="_new"><img style={twitterLogo} src="./header-social-twitter.png" /></a></div>
          <div style={logoContainer}><a href="https://discord.gg/j5ZW5uDBx3" target="_new"><img style={discordLogo} src="./header_social_discord.png" /></a></div>
          </div>
      </div>
    </div>
  )
}

export default SectionFooter
