import * as React from "react";
// import 'assets/scss/App.scss';
import WelcomePage from 'components/WelcomePage';
import Navbar from "../components/Navbar";
import SectionHero from "../sections/landingpage/SectionHero";
import SectionPosters from "../sections/landingpage/SectionPosters";
import SectionTitle from "../sections/landingpage/SectionTitle";
import SectionTopVideo from "../sections/landingpage/SectionTopVideo";
import SectionSyndicatesAtWar from "../sections/landingpage/SectionSyndicatesAtWar";
import SectionHoldToEarn from "../sections/landingpage/SectionHoldToEarn";
import SectionRaccoonHeartbeat from "../sections/landingpage/SectionRaccoonHeartbeat";
import SectionGenesisCollection from "../sections/landingpage/SectionGenesisCollection";
import SectionMintDates from "../sections/landingpage/SectionMintDates";
import SectionSyndicatePreviewVS2 from "../sections/landingpage/SectionSyndicatePreviewVS2";
import SectionSpacer from "../sections/landingpage/SectionSpacer";
import SectionCards from "../sections/landingpage/SectionCards";
import SectionTeam from "../sections/landingpage/SectionTeam";
import SectionGalleryRow from "../sections/landingpage/SectionGalleryRow";
import SectionFAQ from "../sections/landingpage/SectionFAQ";
import SectionFooter from "../sections/landingpage/SectionFooter";
import Modal from "../components/Modal";
import Separator from "../components/Separator";
import transcribe from 'rune-converter';
import {
  useWindowWidth,
} from '@react-hook/window-size/throttled';
import LargeImageModal from "../components/LargeImageModal";


// styles
const pageStyles = {
  color: "#FFF",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundColor: 'rgb(26, 26, 26)'
}




const DesktopHomepage = ({mobile}) => {
  const [shouldShowModal, setShouldShowModal] = React.useState(false);
  const [largeImageModalUrl, setLargeImageModalUrl] = React.useState();
  const [largeImageHiResModalUrl, setLargeImageHiResModalUrl] = React.useState();

  const showLargeImageModal = (imageUrl, imageUrlHiRes) => {
    setLargeImageModalUrl(imageUrl);
    setLargeImageHiResModalUrl(imageUrlHiRes);
    setShouldShowModal(true);
  }

  return (
    <main style={pageStyles}>
      <div id="top">
        {!mobile && !shouldShowModal &&
          <Navbar />
        }
        <SectionHero mobile={mobile} />
        <SectionPosters mobile={mobile} />
        <SectionSyndicatesAtWar mobile={mobile} />
        <SectionHoldToEarn mobile={mobile} />
        <SectionRaccoonHeartbeat mobile={mobile} />
        <div style={{
          marginTop: '0px',
        }}>
          <Separator mobile={mobile} />
        </div>
          <SectionGenesisCollection mobile={mobile} showLargeImageModal={showLargeImageModal} />  
        <div style={{
          marginTop: '100px',
        }}>
          <Separator mobile={mobile} />
        </div>
        <div style={{
          marginTop: '100px'
        }}>
          <SectionSyndicatePreviewVS2 
            syndicateNameLeft={"Farmers"} 
            syndicateImageLeft="https://rswebpublic.s3.amazonaws.com/gfx/syndicate_farmer.png" 
            syndicateDescriptionLeft={"To win a war, first you must feed it's soldiers. As such, while humble in nature, the Farmers Syndicate has grown to become one of the most essential of the 6 syndicates - carving out power and influence fueled by their stranglehold on the world's food supplies. Members of the Farmers Syndicate act as a multiplicative force, increasing the efficiency of any army."}
            supplyLeft={2500}
            syndicateNameRight={"Hunters"} 
            syndicateImageRight="https://rswebpublic.s3.amazonaws.com/gfx/syndicate_hunter.png" 
            syndicateDescriptionRight={"Jeered as the \"Butcher's Syndicate\" in private, the Hunters are primal and violent by nature. They're the brutes of the battlefield and often contracted by all 5 of the other syndicates to execute their will. Social by nature, the Hunters Syndicate rarely travels alone - but instead works in packs to corner their enemy, yielding a most successful outcome. "}
            supplyRight={2500}
            backgroundImageUrl="https://rswebpublic.s3.amazonaws.com/bg/bg-red.png"
            versusImage="https://rswebpublic.s3.amazonaws.com/images/farmersvshunters.png"
            mintPeriod="August 2022"
            id="syndicates"
            firstRow={true}
            mobile={mobile}
          />
        </div>
        <SectionSyndicatePreviewVS2 
          syndicateNameLeft={"Scientists"} 
          syndicateImageLeft="https://rswebpublic.s3.amazonaws.com/raccoons/Scientist_Comp_01.png" 
          syndicateDescriptionLeft={"The driving force behind all technology (lethal and otherwise) in the Raccoon kingdom, the Scientists Syndicate places logic and reason above all else. Often found in their laborities crafting, the Scientists will continue to beat the drums of innovation through time."}
          supplyLeft={1500}
          syndicateNameRight={"Clerics"} 
          syndicateImageRight="https://rswebpublic.s3.amazonaws.com/raccoons/Cleric_Comp_01.png" 
          syndicateDescriptionRight={"The moral arbiters of the Raccoon Kingdom, our righteous Clerics light the way towards ethical living amongst the raccoons. Fail to stand up to their mighty judgement though, and risk feeling not only their wrath, but that of the gods they worship."}
          supplyRight={1500}
          backgroundImageUrl="https://rswebpublic.s3.amazonaws.com/bg/bg-blue.png"
          supplyBoxBackgroundColor={"#00841f"} 
          numSupply={2500}  
          backgroundColor={"#310404"} 
          versusImage="https://rswebpublic.s3.amazonaws.com/gfx/scientistsvsclerics.png"
          mintPeriod="October 2022"
          mobile={mobile}
        />
        <SectionSyndicatePreviewVS2 
          syndicateNameLeft={"Aristocrats"} 
          syndicateImageLeft="https://rswebpublic.s3.amazonaws.com/gfx/aristr1.png" 
          syndicateDescriptionLeft={"The blue blood capitalist Aristocrats see no brethren amongst the other Syndicates but instead believe that their nobility and capital sets them apart from all others. Judging by their near-homogenous control of the Raccoon's society and economy - they may just be right."}
          supplyLeft={1000}
          syndicateNameRight={"Procyon Lotor"} 
          syndicateImageRight="https://rswebpublic.s3.amazonaws.com/raccoons/pl2.png" 
          syndicateDescriptionRight={"Part secret society, part global cabal, the not so secret Procyon lotor lurk amongst the shadows within the halls of power. Struggling against the Aristocrats for power and influence, these puppeteers are often found obsessively conspiring to destroy their rivals at any cost. "}
          supplyRight={1000}
          backgroundImageUrl="https://rswebpublic.s3.amazonaws.com/bg/bg-yellow.png"
          supplyBoxBackgroundColor={"#00841f"} 
          numSupply={2500}  
          versusImage="https://rswebpublic.s3.amazonaws.com/gfx/aristocratsvsprocyonlotor.png"
          backgroundColor={"#310404"} 
          mintPeriod="December 2022"
          mobile={mobile}
        />
        <div style={{
          marginBottom: '250px',
        }}>
          <Separator mobile={mobile} />
        </div>
        <SectionGalleryRow mobile={mobile} showLargeImageModal={showLargeImageModal} />
        <SectionTeam mobile={mobile} />
        <SectionFAQ mobile={mobile} />
        <SectionFooter mobile={mobile} />
        <LargeImageModal shouldShowModal={shouldShowModal} setShouldShowModal={setShouldShowModal} largeImageModalUrl={largeImageModalUrl} largeImageHiResModalUrl={largeImageHiResModalUrl} />
      </div>
    </main>
  )
}


const Wrapper = () => {
  const windowWidth = useWindowWidth();

  if (windowWidth < 900) {
    return DesktopHomepage({mobile: true});
  }
  
  return DesktopHomepage({mobile: false});
}


export default Wrapper;
