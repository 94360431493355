import * as React from "react"
import {
  useWindowHeight, useWindowWidth,
} from '@react-hook/window-size/throttled';

// styles
const sectionRoot = {
}

const sectionInnderDiv = {
}

const heroImageWrapper = {
}

// markup
const Section = (props) => {
  const {mobile} = props;

  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const widthToHeroRatio = windowWidth / 2048;
  const desiredHeight = 1052 * widthToHeroRatio;

  return (
    <div style={{
      ...sectionRoot,
      display: 'flex',
      flexDirection: mobile ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '50px'
    }}>
          <a href="https://rswebpublic2.s3.amazonaws.com/wppdf/RaccoonSyndicate_Whitepaper_v1.1.2.pdf" style={{textAlign: 'center'}} target={mobile ? undefined : "_blank"}>
            <img src="https://rswebpublic2.s3.amazonaws.com/webgfx/hp_poster_whitepaper.png" style={{
              width: mobile ? '80%' : '350px',
              margin: '10px 25px',
              border: '1px solid #f3dbb3',
              cursor: 'pointer'
            }}/>
          </a>
          <a href="https://game1.raccoonsyndicate.com" style={{textAlign: 'center'}}>
            <img src="https://rswebpublic2.s3.amazonaws.com/webgfx/hp_poster_game1.png" style={{
              width: mobile ? '80%' : '350px',
              margin: '10px 25px',
              border: '1px solid #f3dbb3',
              cursor: 'pointer'
            }}/>
          </a>
          <a href="https://discord.gg/rsyndicate" style={{textAlign: 'center'}} target={mobile ? undefined : "_blank"}>
            <img src="https://rswebpublic2.s3.amazonaws.com/webgfx/hp_poster_joindiscord.png" style={{
              width: mobile ? '80%' : '350px',
              margin: '10px 25px',
              border: '1px solid #f3dbb3',
              cursor: 'pointer'
            }}/>                    
          </a>            
    </div>
  )
}

export default Section
