import * as React from "react"
import {
  useWindowHeight,
} from '@react-hook/window-size/throttled';

// styles
const root = {
}

const sectionInnerDiv = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
}

const nameText = {
  fontFamily: `"Croissant One"`,
}

const titleText = {
  lineHeight: '1.1em',
  fontFamily: 'Lato',
  marginTop: '7px',
}

const descriptionText = {
  lineHeight: '1.4em',
  fontFamily: 'Lato',  
}

const imageWrapper = {
}

const TeamMember = (props) => {
  const {name, title, description, backgroundColor, image, textAlignment, mobile} = props;

  const onlyHeight = useWindowHeight();
  
  return (
    <div style={{
        ...root,
        margin: mobile ? '0px 0px 90px 0px' : '0px 4vw'
      }}>
          <div style={{
              backgroundColor: backgroundColor,
              ...sectionInnerDiv,
              width: mobile ? '100%' : '25vw',
              padding: mobile ? null : '10px 30px 30px 30px',
              textAlign: mobile ? 'center' : null
          }}>
              <div style={imageWrapper}>
                <img src={ image ? image : "https://rswebpublic.s3.amazonaws.com/images/Common_01.png"} style={{
                  width: mobile ? '80%' : `25vw`,
                }}/>
              </div>
              <div 
                style={{
                  ...nameText,
                  textAlign: textAlignment === 'right' && !mobile ? 'right' : 'left',
                  fontSize: mobile ? '2.2rem' : '2.4vw',
                  padding: mobile ? '0px 50px' : null
                }}
              >
                  {name}
              </div>
              <div 
                style={{
                  ...titleText,
                  textAlign: textAlignment === 'right' && !mobile ? 'right' : 'left',
                  fontSize: mobile ? '1.6rem' : '1.4vw',
                  padding: mobile ? '0px 50px' : null
                }}
              >
                {title}
              </div>
              <div 
                style={{
                  ...descriptionText,
                  textAlign: textAlignment === 'right' && !mobile ? 'right' : 'left',
                  fontSize: mobile ? '1.2rem' : '1.2vw',
                  padding: mobile ? '0px 50px' : null,
                  marginTop: mobile ? '15px' : '30px'
                }}
              >
                {description}
              </div>
          </div>
    </div>
  )
}

export default TeamMember
