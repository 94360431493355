import * as React from "react"

// styles
const container = {
  textAlign: 'center'
}

const separatorImg = {
  width: '70%'
}


const Separator = (props) => {
  const {} = props;

  return (
    <div style={container}>
       <img style={separatorImg} src="https://rswebpublic.s3.amazonaws.com/gfx/rsseparator.png" />
    </div>
  )
}

export default Separator
