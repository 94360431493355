import * as React from "react"

// styles
const modalWrapper = {
  display: 'none',
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0, 0, 0)',
  backgroundColor: 'rgba(0, 0, 0, 0.85)',
  overflowY: 'scroll'
}

const modalDiv = {
  backgroundColor: '#222',
  margin: '1% auto',
  padding: '40px',
  border: '1px solid #888',
  width: '80%',
  height: '88%',
  overflowY: 'scroll'
}

const genesisCollectionText = {
  fontSize: '3em'
}

const genesisDescription1 = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const genesisDescription2 = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const tier3FarmersHuntersForWL = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const modalBodyStyle = {
  color: '#ccc'
}


// markup
const Section = (props) => {
  const {showModal, setShowModal, children} = props;

  console.log('showModal is', showModal);
  console.log('children are', children);

  return (
    <div style={{
      ...modalWrapper,
      display: showModal === true ? 'block' : 'none'
    }}
    onClick={() => {
      setShowModal(false);
    }}
    >
      <div style={modalDiv}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      >
          <div style={{
            fontSize: '2em',
            color: '#000',
            overflowY: 'scroll'
          }}>
              <div style={modalBodyStyle}>
                {children}
              </div>
          </div>
      </div>
    </div>
  )
}

export default Section
