import * as React from "react"
import styled from 'styled-components';

// styles
const sectionRoot = {
}

const sectionInnerDiv = {
  padding: '150px 0px 200px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const genesisCollectionText = {
  fontSize: '3em'
}

const genesisDescription1 = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const genesisDescription2 = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const tier3FarmersHuntersForWL = {
  fontSize: '1.7em',
  marginTop: '50px'
}

const galleryRow = {
  marginTop: '100px',
  width: '100%'
}

const imgStyle = {
  height: '400px',
  width: '400px'
}

const topRow = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
}

const leftColumn = {
  display: 'flex', 
  flex: 0.5,
  alignItems: 'center',
  justifyContent: 'flex-end',
}

const rightColumn = {
  display: 'flex',
  flexDirection: 'column',
  flex: 0.5,
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingLeft: '50px'
}

const logoContainer = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}

const projectLogo = {
  width: '100%',
  minWidth: '350px'
}

const largeText = {
  fontSize: '3.5em',
  marginBottom: '40px',
  color: '#fad3a7',
  textAlign: 'center'
}

const largeTextLine1 = {
  fontSize: '3.5vw',
  color: '#fad3a7',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  fontFamily: "Reggae One"
}

const largeTextLine2 = {
  fontSize: '3.5vw',
  marginBottom: '40px',
  color: '#fad3a7',
  textAlign: 'center',
  fontFamily: "Reggae One"
}

const medText = {
  fontSize: '1.6vw',
  lineHeight: '2.3vw',
  fontFamily: 'Lato'
}

const Wrapper = styled.div`
  width: 100%;
  
  li.alice-carousel__stage-item :not(.__cloned) {
    width: auto !important;
    margin-right: 3rem;
  }
`;

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 4 },
  1024: { items: 8 },
};

const items = [
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/farmer_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/hunter_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/scientist_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/cleric_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/invisibilus_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/aristocrat_master.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/farmer_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/hunter_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/scientist_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/cleric_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/invisibilus_apprentice.jpeg" onDragStart={handleDragStart} />,
  <img style={imgStyle} src="https://rswebpublic.s3.amazonaws.com/raccoons/aristocrat_apprentice.jpeg" onDragStart={handleDragStart} />,
];


// markup
const Section = (props) => {
  const {mobile} = props;
  
  return (
    <div style={{
      ...sectionRoot,
    }}>
      <div style={{
        ...sectionInnerDiv,
        padding: mobile ? '100px 0px' : '150px 0px 140px 0px',
        }}>
          <div style={{
          ...topRow,
          padding: mobile ? '0px 5%' : '0% 15%',
          flexDirection: mobile ? 'column' : 'row'
          }}>
          <div style={{
            ...leftColumn,
            paddingRight: mobile ? '0px' : '50px',
          }}>
            <div style={logoContainer}>
              <img style={projectLogo} src="https://rswebpublic.s3.amazonaws.com/gfx/hold-to-earn-raccoon.png" />
            </div> 
          </div>
          <div style={{
            ...rightColumn,
            paddingTop: mobile ? '50px' : '0px',
            paddingLeft: mobile ? '0px' : '50px'
          }}>
            {!mobile && 
              <>
                <div style={{
              ...largeTextLine1,
                fontSize: mobile ? '2.6rem' : '2.7vw',
              }}>Bringing Blockchain Gaming</div>
              <div style={{
                ...largeTextLine2,
                fontSize: mobile ? '2.6rem' : '2.7vw'
              }}>to Cardano!</div>
              </>
            }
            {mobile && 
              <>
                <div style={{
              ...largeTextLine2,
                fontSize: mobile ? '2.6rem' : '3.8vw',
              }}>Bringing Blockchain Gaming to Cardano!</div>
              </>
            }
            <div style={{
              ...medText,
              fontSize: mobile ? '1.4rem' : '1.6vw',
              lineHeight: mobile ? '1.8rem' : '2.3vw',
            }}>Win 'Battle Points' every Epoch (5 days) by owning one of 10,000 Raccoon Syndicate NFTs and playing our online (web) game. Every 10 Epochs (50 days) prizes are given to Raccoon owners based on Battle Points won. Prizes may include Raccoon Upgrade NFTs - allowing you to win more Battle Points in the future - or partner project NFTs!</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section
