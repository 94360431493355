import * as React from "react"
import {
  useWindowHeight, useWindowWidth,
} from '@react-hook/window-size/throttled';

// styles
const sectionRoot = {
}

const sectionInnderDiv = {
}

const heroImageWrapper = {
}

// markup
const Section = (props) => {
  const {mobile} = props;

  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const widthToHeroRatio = windowWidth / 2048;
  const desiredHeight = 1052 * widthToHeroRatio;

  return (
    <div style={{
      ...sectionRoot,
      paddingTop: mobile ? null : '38px',
      height: `${desiredHeight}px`
    }}>
          {/*<StaticImage src="../../images/racSamples/secret-green-cloak.jpg" alt="Secret Society Green Cloak" width={800} height={800} />*/}
          <img src="https://rswebpublic2.s3.amazonaws.com/rstophero.jpeg" style={{
            width: '100%',
          }}/>
    </div>
  )
}

export default Section
